import * as u from 'JS/utils';

u.domReady(() => {
  let h = document.getElementById("header");
  let stuck = false;
  let stickPoint = getDistance();

  function getDistance() {
    var topDist = h.offsetTop;
    return topDist;
  }
  window.onscroll = function(e) {
    var distance = getDistance() - window.pageYOffset;
    var offset = window.pageYOffset;
    if ( (distance <= 0) && !stuck) {
      h.style.position = 'fixed';
      h.classList.add("header-stuck")
      stuck = true;
    } else if (stuck && (offset <= stickPoint)){
      h.style.position = 'absolute';
      h.classList.remove("header-stuck")
      stuck = false;
    }
  }
})
