// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'
import 'element-closest-polyfill'
import 'focus-visible/dist/focus-visible.min.js'
// import "core-js/modules/es7.promise.finally";
import CustomEvent from 'custom-event-polyfill';

// Object-fit & object-position support for older browsers
import objectFitImages from 'object-fit-images';
objectFitImages('img', {watchMQ: true});
import "./scripts/scrolling"
//import "./scripts/hero_hack"
import "./scripts/gtm_tracking"

// document.addEventListener('turbolinks:load', function (event) {
// 	if(typeof ga === 'function') {
// 		ga('set', 'location', event.data.url)
// 		ga('send', 'pageview')
//   }
// 	if(typeof gtag === 'function') {
//     gtag('config', '', {
//       'page_location': event.data.url
//     });
//     if(event.data.url.endsWith('/contact')) {
//       gtag('event', 'conversion', {'send_to': 'AW-994590559/a5qmCJWh_usBEN_-oNoD'});
//     }
//   }
// })

// ------------------

// Gather all js files from components folders


  import '/hindles/app/components/example/test.js';

  import '/hindles/app/components/core/header/header.js';

  import '/hindles/app/components/core/header/navigation/header-navigation.js';

  import '/hindles/app/components/testimonials/carousel/carousel.js';

  import '/hindles/app/components/cookies_bar/cookies-bar.js';


