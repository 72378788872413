import * as u from 'JS/utils'

import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css';

const swiperOptions = {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  pagination: {
    el: '.carousel-nav',
    type: 'bullets',
    bulletClass: 'carousel-nav__bullet',
    bulletActiveClass: 'carousel-nav__bullet--active',
  },
  navigation: {
    nextEl: '.swiper-next',
  },
  autoplay: {
    delay: 8000,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true
  },
  on: { // below is to fix bad width calculated for slides
    init() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200)
    },
  },

}

u.domReady(function() {
  const swiperContainer = document.getElementById('swiper')

  var mySwiper = new Swiper(swiperContainer, swiperOptions);
});
