import * as u from '../utils'

const SmoothScroll = require("smooth-scroll")
const scroll = new SmoothScroll('a[href*="#"]', {
  offset: 60
});

u.domReady(function() {
  window.scrollTo(0, 0)
});
