import { Controller } from '@hotwired/stimulus'
import AddSearchClient from 'addsearch-js-client'

export default class extends Controller {
  initialize() {
    this.toggleSpinner(false)
    this.pagination = 10
    this.initial_search = window.location.search
    this.client = new AddSearchClient('46352d37671b0446b1fba40afcd8d2c6')
    this.client.setPaging(1, this.pagination, 'relevance', 'desc')
    this.client.setFuzzyMatch(false)
    this.contentPosition = document.getElementById('content').getBoundingClientRect().top - 100
    // 0x for domain, 1x for first path level, 2x for second etc.
    // We filter the domain so SBT can use a single addSearch account for multiple sites
    // https://www.addsearch.com/docs/indexing/restrict-search-area
    this.client.setCategoryFilters('0xwww.hindles.co.uk')
    this.getRedirectedSearch()
  }

  static targets = [
    'searchDetails',
    'searchResults',
    'searchInput',
    'searchHeader',
    'nextPage',
    'prevPage',
    'clear',
    'paginationLinks',
  ]

  search(search_term) {
    const parentNode = this.searchResultsTarget
    this.clear(parentNode)
    this.toggleSpinner(true)
    let response_size = null
    let pages = null
    let total_pages = null
    let current_page = null

    this.client.search(search_term, (res) => {
      response_size = res.total_hits
      total_pages = Math.ceil(res.total_hits / this.pagination)
      current_page = res.page

      setTimeout(() => {
        this.searchHeaderTarget.classList.add('visible')
        this.searchHeaderTarget.classList.remove('hidden')
        this.clearTarget.classList.remove('hidden')
        this.showSearchResultInfo(
          search_term,
          res.total_hits,
          Math.ceil(res.total_hits / this.pagination),
          current_page
        )
        this.toggleSpinner(false)
        res.hits.forEach((result, index) => {
          this.renderLink(result, index, search_term)
        })
      }, 500)

      return false
    })

    return false
  }

  toggleSpinner(spinning) {
  //  const spinner = document.querySelector('.filterrific_spinner')

  //  if (spinning) {
  //    spinner.style.display = 'inline-block'
  //  } else {
  //    spinner.style.display = 'none'
  //  }
  }

  handleNextPage(e) {
    e.preventDefault()
    this.deliverNextPage(this.searchInputTarget.value)
  }

  handlePrevPage(e) {
    e.preventDefault()
    this.deliverPrevPage(this.searchInputTarget.value)
  }

  showSearchResultInfo(search_term, total_results, total_pages, current_page) {
    this.paginationDisplay(current_page, total_pages)
    let hits_plural = total_results == 1 ? 'result' : 'results'
    const pages_text =
      total_pages > 1
        ? `Page <strong class="search_details--strong">${current_page}</strong> of <strong class="search_details--strong">${total_pages}</strong>.`
        : ''
    this.searchDetailsTarget.innerHTML = `Your search for <strong class="search_details--strong">${search_term}</strong> has <strong class="search_details--strong">${total_results}</strong> ${hits_plural}. ${pages_text}`
  }

  paginationDisplay(current_page, total_pages) {
    if(total_pages == 1) {
      this.paginationLinksTarget.classList.add('hidden')
    }
    if(total_pages > 1) {
      this.paginationLinksTarget.classList.remove('hidden')
    }
    if (current_page >= total_pages) {
      this.nextPageTargets.forEach((target) => {
        target.classList.add('hidden')
      })
    } else {
      this.nextPageTargets.forEach((target) => {
        target.classList.remove('hidden')
      })
    }

    if (current_page == 1) {
      this.prevPageTargets.forEach((target) => {
        target.classList.add('hidden')
      })
    } else {
      this.prevPageTargets.forEach((target) => {
        target.classList.remove('hidden')
      })
    }
  }

  deliverPrevPage(term) {
    this.client.previousPage()
    this.search(term)
    window.scrollTo(0, this.contentPosition)
  }

  deliverNextPage(term) {
    this.client.nextPage()
    this.search(term)
    window.scrollTo(0, this.contentPosition)
  }

  clear(node) {
    while (node.firstChild) node.removeChild(node.firstChild)
  }

  linkClick(e) {
    e.preventDefault()
    const id = e.currentTarget.getAttribute('id')
    const position = e.currentTarget.getAttribute('data-position')
    const keyword = e.currentTarget.getAttribute('data-keyword')

    this.client.sendStatsEvent('click', keyword, {
      document_id: id,
      position: position,
    })

    window.location.href = e.currentTarget.getAttribute('href')
  }

  renderLink(result, index, keyword) {
    const node = document.createElement('div')
    const title = document.createElement('h3')
    const highlight = document.createElement('p')
    const url = document.createElement('a')
    highlight.innerHTML = result.meta_description
    title.innerHTML = result.title
    title.setAttribute('class', 'h3')
    highlight.setAttribute('class', 'para')

    // tracked attributes
    node.setAttribute('data-keyword', keyword)
    node.setAttribute('data-position', index)
    node.setAttribute('id', result.id)
    node.setAttribute('style', 'margin-bottom: 2rem;')

    node.setAttribute('href', result.url)
    url.innerHTML = 'View page'
    node.setAttribute('data-action', 'click->search#linkClick')
    url.setAttribute('href', result.url)
    url.setAttribute('class', 'link')
    node.appendChild(title)
    node.appendChild(highlight)
    node.appendChild(url)
    node.classList.add('result-item')
    this.searchResultsTarget.appendChild(node)
  }

  getRedirectedSearch() {
    if (this.initial_search) {
      const search = this.initial_search.split('?')
      this.searchInputTarget.value = decodeURIComponent(search[1])

      if (
        this.searchInputTarget.value == null ||
        this.searchInputTarget.value.length == 0
      ) {
        this.clearSubmit(e)
        return
      }

      this.search(this.searchInputTarget.value)
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    if (
      this.searchInputTarget.value == null ||
      this.searchInputTarget.value.length == 0
    ) {
      this.clearSubmit(e)
      return
    }
    window.history.pushState(
      {},
      document.title,
      '?' + encodeURIComponent(this.searchInputTarget.value)
    )
    this.client.setPaging(1, this.pagination, 'relevance', 'desc')
    this.search(this.searchInputTarget.value)
  }

  clearSubmit(e) {
    e.preventDefault()
    this.clearTarget.classList.add('hidden')
    const fresh_url = location.href.split('?')[0]
    window.history.pushState({}, document.title, fresh_url)
    this.searchHeaderTarget.classList.remove('visible')
    this.searchHeaderTarget.classList.add('hidden')
    this.paginationDisplay(1, 0)
    this.searchInputTarget.value = ''
    this.clear(this.searchResultsTarget)
    this.clear(this.searchDetailsTarget)
    window.scrollTo(0, this.contentPosition)
  }
}