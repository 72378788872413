import * as u from 'JS/utils';

u.domReady(() => {
  const body = document.getElementsByTagName('body')[0]
  const nav = document.getElementsByClassName('header-navigation')[0]
  const input = document.getElementById('navigation_main')
  const dropdownButtons = document.getElementsByClassName('header-link--has-children')
  const dropdowns = document.getElementsByClassName('header-navigation_item')

  document.addEventListener('click', function() {
    closeAllDropdowns(dropdowns)
  })

  if(nav != undefined && input != undefined) {

    input.addEventListener('change', (e) => {
      e.stopImmediatePropagation();
      if(input.checked && !nav.classList.contains('open')) {
        nav.classList.add('open')
        body.classList.add('no-scroll')
      } else {
        nav.classList.remove('open')
        body.classList.remove('no-scroll')
      }
    })

    Array.from(dropdownButtons).forEach(button => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();

        if (button.parentElement.classList.contains('open')) {
          e.target.blur()
          button.parentElement.classList.remove('open')
        } else {
          closeAllDropdowns(dropdowns)
          button.parentElement.classList.toggle('open')
        }

        //  This has to go as it causes some troubles on webkit
        // if (button.parentElement.classList.contains('open')) {
        //   u.onClickOutside(button, () => closeAllDropdowns(dropdowns))
        // }
      })
    })
  }
})

const closeAllDropdowns = (elements) => {
  if (window.innerWidth >= 950) {
    Array.from(elements).forEach(el => {
      el.classList.contains('open') ? el.classList.remove('open') : null
    })
  }
}
