import * as u from '../utils'
u.domReady(function() {
  const elements = document.querySelectorAll('[href*="mailto:"]')
  elements.forEach(function(element) {
    element.addEventListener('click', function() {
      if(typeof gtag === 'function') {
        gtag('event', 'conversion', {'send_to': 'AW-994590559/3Y4mCMPy0u4CEN_-oNoD'});
      }
    })
  })
})
